import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CodeMirror, { ReactCodeMirrorProps } from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { keymap, KeyBinding, EditorView } from "@codemirror/view";
import { defaultKeymap } from "@codemirror/commands";

import { setScript, StoreState } from "./store";

export const Interface: React.FC = () => {
  const scriptRef = useRef<HTMLScriptElement>();
  const containerRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState({ isInitialEvalDone: false });
  const script = useSelector((state: StoreState) => state.config.script);
  const dispatch = useDispatch();

  const evalScript = (script: string) => {
    if (!containerRef.current) {
      return false;
    }

    containerRef.current.innerHTML = "";

    scriptRef.current = document.createElement("script");
    scriptRef.current.type = "module";
    scriptRef.current.textContent = script;
    containerRef.current.appendChild(scriptRef.current);

    return true;
  };

  useEffect(() => {
    if (script && !state.isInitialEvalDone) {
      evalScript(script);
      setState((state) => ({ ...state, isInitialEvalDone: true }));
    }
  }, [script, state]);

  const onChange: ReactCodeMirrorProps["onChange"] = (script, viewUpdate) => {
    dispatch(setScript(script));
  };

  let myTheme = EditorView.theme(
    {
      "&.cm-focused .cm-cursor": {
        borderLeftColor: "#ffffff",
      },
      ".cm-selectionBackground": {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      },
      "&.cm-focused .cm-selectionBackground": {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      },
      ".cm-activeLine": {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      },
      ".cm-gutters": {
        backgroundColor: "rgba(0, 0, 0, 0.0)",
        border: "none",
      },
      ".cm-scroller::-webkit-scrollbar": {
        display: "none",
      },
      ".cm-scroller": {
        scrollbarWidth: "none",
      },
      ".cm-line": {
        color: "white",
      },
    },
    { dark: true }
  );

  const myKeymap: KeyBinding[] = [
    {
      key: "Ctrl-Shift-Enter",
      run: (view) => (script ? evalScript(script) : false),
    },
  ];

  const extensions = [
    javascript({ jsx: true, typescript: true }),
    keymap.of([...defaultKeymap, ...myKeymap]),
  ];

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ flex: 1, overflow: 'hidden' }} ref={containerRef} id="container"></div>
      <CodeMirror
        style={{ position: "absolute", top: 0, width: "100%", height: "100%" }}
        width="100%"
        height="100%"
        theme={myTheme}
        value={script}
        extensions={extensions}
        onChange={onChange}
      />
    </div>
  );
};
