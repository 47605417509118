import {
  createStore,
  createAction,
  createReducer,
  combineReducers,
  CombinedState,
} from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";

type ConfigSliceState = {
  version?: string;
  script?: string;
};

const initialState: ConfigSliceState = {};

export const setScript = createAction<string, "setScript">("setScript");
export const setVersion = createAction<string, "setVersion">("setVersion");

const configReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setScript, (state, action) => {
      state.script = action.payload;
    })
    .addCase(setVersion, (state, action) => {
      state.version = action.payload;
    })
);

const rootReducer = combineReducers({
  config: configReducer,
});

const persistedReducer = persistReducer({ key: "root", storage }, rootReducer);

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);

export type StoreState = CombinedState<{
  config: ConfigSliceState;
}>;
