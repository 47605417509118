import React from "react";
import { Provider } from "react-redux";

import { KELLER_STATIC_URL, KELLER_MEDIA_URL } from "./constants";
import { store, persistor, setScript, setVersion } from "./store";
import { Interface } from "./Interface";

(window as any).KELLER_STATIC_URL = KELLER_STATIC_URL;
(window as any).KELLER_MEDIA_URL = KELLER_MEDIA_URL;

persistor.subscribe(async () => {
  const state = store.getState();

  const { script } = (await (
    await fetch(`${KELLER_STATIC_URL}/scripts/index.json`)
  ).json()) as { script: string };

  if (state.config.version !== script || !state.config.script) {
    const response = await fetch(`${KELLER_STATIC_URL}${script}`);
    const scriptText = await response.text();
    store.dispatch(setScript(scriptText));
    store.dispatch(setVersion(script));
  }
});

export const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Interface />
    </Provider>
  );
};
